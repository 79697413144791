import React, { useEffect, useRef, useState } from "react";
import { Enums, eventTarget, EVENTS } from "@cornerstonejs/core";
import { ProgressBar } from "./ProgressBar";

const { ViewportType } = Enums;

export const Thumbnail = ({
  serie,
  stack,
  study,
  engine,
  setThumbName,
  onClick,
  isClicked,
  setMgViewports,
}) => {
  const elementRef = useRef(null);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const ren = async () => {
      const viewportId = `CT_STACK${serie}`;
      const element = elementRef.current;

      if (!element) {
        console.error("Element not found.");
        return;
      }

      const viewportInput = {
        viewportId,
        type: ViewportType.STACK,
        element,
      };
      engine.enableElement(viewportInput);
      const viewport = engine.getViewport(viewportId);
      if (!viewport) {
        console.error("Viewport not created.");
        return;
      }
      try {
        await viewport.setStack(stack.filter(Boolean));
        const image = await viewport.getCornerstoneImage(0);
        let name = (image && image.data && image.data.string('x0008103e')) ? image.data.string('x0008103e'):''
        setThumbName((name.length > 1) ? name.slice(0, 15) : '' )
        if(study === 'MG'){
          if(image.windowCenter< 4000){
            setMgViewports(old => [...old, viewport]);
            viewport.render();
          } else{
            element.parentNode.remove(element);
          }
        }else{
          // viewport.setProperties({ voiRange: { upper: 1401, lower :790} });
          viewport.render();
        }
      } catch (error) {
        element.parentNode.remove(element);
        console.error("Error rendering:", error);
      }
    };
    eventTarget.addEventListener(EVENTS.IMAGE_LOAD_PROGRESS, (e) => {
      
      if (e.detail.imageId === stack[1]) {
        if (e.detail.percentComplete === 100 && study === "MG") {
      
          setTimeout(() => {
            setProgress(e.detail.percentComplete);
          }, 2500);
        } else {
      
          setProgress(e.detail.percentComplete);
        }
      }
    });
    
    ren();
  }, [engine, serie, stack]);

  const thumbStyle = {
    border: isClicked ? "1px solid #4033F2" : "none",
    width: "250px",
    height: "180px",
  };
  return (
    <>
      <div
        ref={elementRef}
        id={`thumb-${serie}`}
        className="d-flex flex-column overflow-hidden rounded-3"
        style={thumbStyle}
        onClick={onClick}
      />
      {/* {progress < 100 && <ProgressBar progress={progress} />} */}
    </>
  );
};
