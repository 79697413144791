import { CubeIcon } from "@heroicons/react/24/outline";

export const MprToolbarButton = ({
  redIconStyle,
  handleMpr,
  isStackLengthMoreThan100,
}) => {
  const options = [
    { name: "Axial" },
    { name: "Sagittal" },
    { name: "Coronal" },
    { name: "MPR 3D" },
  ];

  const handleM = (element) => {
    handleMpr(element.name.toUpperCase());
  };
  return (
    <div className="btn-group">
      <button
        className={`btn btn-dark dropdown-toggle d-flex flex-column align-items-center py-2 ${
          !isStackLengthMoreThan100 && "disabled"
        }`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ fontSize: "11px" }}
      >
        <CubeIcon style={redIconStyle} />
        MPR
      </button>
      <ul
        className="dropdown-menu dropdown-menu-dark w-auto text-center"
        style={{ fontSize: "12px" }}
      >
        {options.map((element) => {
          return (
            <li
              key={element.name}
              className="dropdown-item"
              onClick={() => handleM(element)}
            >
              {element.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
