import React from "react";

export const ToolButton = ({
  icon,
  name,
  onClick,
  isActive,
  className,
  isLeftButton,
  isRightButton,
}) => {
  const buttonStyle = {
    borderBottom: isActive ? "4px solid #4033F2" : "none",
  };

  return (
    <button
      className={`btn btn-dark d-flex flex-column align-items-center py-2 ${className} position-relative `}
      style={buttonStyle}
      onClick={onClick}
      onContextMenu={onClick}
    >
      {icon}
      {isLeftButton && (
        <span className="position-absolute top-0 start-0  badge rounded-pill" style={{backgroundColor: '#4033F2'}}>
          L<span className="visually-hidden">unread messages</span>
        </span>
      )}
      {isRightButton && (
        <span className="position-absolute top-0 end-0  badge rounded-pill" style={{backgroundColor: '#4033F2'}}>
          R<span className="visually-hidden">unread messages</span>
        </span>
      )}
      <div className="fw-light text-white" style={{ fontSize: "11px" }}>
        {name}
      </div>
    </button>
  );
};
