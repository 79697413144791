import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import ToolsRadioButtons from "./ToolsRadioButtons";


const ConfigModal = ({
  iconStyle,
  setLeftClick,
  setRightClick,
  setLeftActiveButton,
  setRightActiveButton,
  activeTool,
  setActiveButton
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userLeftClick, setUserLeftClick] = useState();
  const [userRightClick, setUserRightClick] = useState();
  const toolsNames = {
    Pan: "Mover",
    Zoom: "Zoom",
    Magnify: "Magnificar",
    PlanarRotate: "Rotar",
    WindowLevel: "Brillo/Contraste",
    Length: "Medición",
    Angle: "Ángulos",
    Probe: "ROI PF",
    RectangleROI: "ROI Rect",
    EllipticalROI: "ROI Elíptica",
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeAndResetModal = () => {
    setIsModalOpen(false);
  };

  const setTools = (e) => {
    e.preventDefault();
    if (userLeftClick && userRightClick) {
      setActiveButton(toolsNames[userLeftClick]);
      setLeftClick(userLeftClick);
      setRightClick(userRightClick);
      setLeftActiveButton(toolsNames[userLeftClick]);
      setRightActiveButton(toolsNames[userRightClick]);
      activeTool({ right: userRightClick, left: userLeftClick });
    }

    closeAndResetModal();
  };

  return (
    <div>
      <button
        className={`btn btn-dark d-flex flex-column align-items-center py-2 h-100`}
        data-bs-toggle="modal"
        data-bs-target="#modal"
        onClick={openModal}
      >
        <Cog6ToothIcon style={iconStyle} />
        <div className="fw-light text-white" style={{ fontSize: "11px" }}>
          Config
        </div>
      </button>

      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="modal"
        tabIndex="-1"
        aria-labelledby="modalLabel"
        aria-hidden={!isModalOpen}
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalLabel">
                Configuración del usuario
              </h1>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={setTools}>
              <div className="modal-body">
                <table className="table text-start">
                  <tbody>
                    <tr>
                      <td className="fw-semibold">
                        Herramienta de inicio click derecho (R)
                      </td>
                      <td>
                        <ToolsRadioButtons
                          setClick={setUserRightClick}
                          groupName={"rightClick"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-semibold">
                        Herramienta de inicio click Izquierdo (L)
                      </td>
                      <td>
                        <ToolsRadioButtons
                          setClick={setUserLeftClick}
                          groupName={"leftClick"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={closeAndResetModal}
                >
                  Cerrar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Guardar cambios
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigModal;
