// import { ellipse } from "@cornerstonejs/tools/dist/esm/utilities/math";
import { CursorArrowRaysIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
export const MipToolbarButton = ({
  redIconStyle,
  handleIntensity
}) => {

    const [mipRange,setMipRange] = useState(0)

    const changeRangeMip = (range) =>{
      setMipRange(range)
      handleIntensity(range)
    }

  
  return (
    <div className="btn-group">
      <button
        className={`btn btn-dark dropdown-toggle d-flex flex-column align-items-center py-2 `}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ fontSize: "11px" }}
      >
        <CursorArrowRaysIcon style={redIconStyle}/>
        MIP
      </button>
      <ul
        className="dropdown-menu dropdown-menu-dark w-auto text-center"
        style={{ fontSize: "12px" }}
      >
       
        <label className="form-label">{mipRange}</label>
        <input value={mipRange} type="range" min="0" max="100" className="form-range" id="customRange1" onChange={e => changeRangeMip(e.target.value)}></input>
       
      </ul>
    </div>
  );
};
