import { useState } from "react";
import { SidebarThumbnail } from "./SidebarThumbnail";

export const Sidebar = ({
  series,
  imageList,
  study,
  engine,
  onThumbnailClick,
  setMgViewports,
  
}) => {
  const [clickedThumbnailIndex, setClickedThumbnailIndex] = useState(0);

  const handleThumbnailClick = (index, serie) => {
    onThumbnailClick({ stack: imageList[serie], serie });
    setClickedThumbnailIndex(index);
  };
  
  return (
    <div
      className="bg-dark p-3 sidebar h-100"
      style={{ overflow: "auto" }}
      id="SIDE"
    >
      {series.map((serie, i) => (
        <SidebarThumbnail
          key={i}
          serie={serie}
          study={study}
          stack={imageList[serie]}
          engine={engine}
          onClick={() => handleThumbnailClick(i, serie)}
          isClicked={i === clickedThumbnailIndex}
          setMgViewports={setMgViewports}
        />
      ))}
    </div>
  );
};
