import { useEffect, useState } from "react";

import { toPng } from "html-to-image";
import {
  PanTool,
  EllipticalROITool,
  ZoomTool,
  LengthTool,
  ProbeTool,
  AngleTool,
  RectangleROITool,
  utilities as csToolsUtilities,
  PlanarRotateTool,
  WindowLevelTool,
  MagnifyTool,
  annotation,
  ReferenceLinesTool,
  CrosshairsTool,
} from "@cornerstonejs/tools";
import {
  ChevronRightIcon,
  ArrowsRightLeftIcon,
  ArrowUturnDownIcon,
  ArrowPathRoundedSquareIcon,
  RectangleStackIcon,
  ArrowUturnRightIcon,
  MagnifyingGlassPlusIcon,
  MagnifyingGlassCircleIcon,
  EllipsisHorizontalCircleIcon,
  ArrowsPointingOutIcon,
  ViewfinderCircleIcon,
  FilmIcon,
  SunIcon,
  TrashIcon,
  ArrowPathIcon,
  DocumentArrowDownIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  EyeDropperIcon,
  StopCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { ToolButton } from "./ToolButton";
import { DisplayToolbarButton } from "./DisplayToolbarButton";
import { WindowToolbarButton } from "./WindowToolbarButton";
import { MprToolbarButton } from "./MprToolbarButton";
import { MipToolbarButton } from "./MipToolbarButton";
import { TagsModal } from "./TagsModal";
import ConfigModal from "./ConfigModal";

export const ToolBar = ({
  activeTool,
  flip,
  playClip,
  stopClip,
  isStackLengthMoreThan100,
  isStackLengthMoreThan5,
  setInvertColors,
  invertColors,
  isCinePlaying,
  setFramesPerSecond,
  framesPerSecond,
  activeViewport,
  renderingEngine,
  setisDescriptionActive,
  isDescriptionActive,
  setNumViewports,
  numViewports,
  convertToMpr,
  resetViewport,
  toolGroup,
  setDescriptions,
  isActiveMPR,
  setIsActiveMPR,
  handleCancelMPR,
  activeMprViewport,
  setIntensity,
  setLeftClick,
  setRightClick,
  setActiveCrosshairs,
  activeCrosshairs,
  disableCrosshairs,
  setPreviousViewportInfo,
}) => {
  const iconStyle = { width: "18px", height: "18px", color: "white" };
  const redIconStyle = { width: "18px", height: "18px", color: "red" };

  useEffect(() => {
    if (isActiveMPR) {
      setActiveButton("Líneas MPR");
      setIsMprLinesActive(true);
    }
  }, [isActiveMPR]);

  const [activeButton, setActiveButton] = useState("Mover");
  const [leftActiveButton, setLeftActiveButton] = useState("Mover");
  const [rightActiveButton, setRightActiveButton] = useState("Zoom");
  const [isMprLinesActive, setIsMprLinesActive] = useState(false);

  const handleToolClick = (e, tool, name) => {
    e.preventDefault();
    setActiveButton(name);
    stopClip();

    let setOnMouseButton;
    if (e.button === 0) {
      setOnMouseButton = "left";
      setLeftActiveButton(name);
      setLeftClick(tool.toolName);
    } else if (e.button === 1) {
      // Click de la rueda del mouse (botón central)
      setOnMouseButton = "auxiliary";
    } else if (e.button === 2) {
      // Click derecho
      setOnMouseButton = "right";
      setRightActiveButton(name);
      setRightClick(tool.toolName);
    }

    if (tool === CrosshairsTool) {
      
      if (activeCrosshairs) {
        setIsMprLinesActive(false);
        setActiveCrosshairs(false)
        disableCrosshairs();
      } else {
        setIsMprLinesActive(true);
        setActiveCrosshairs(true)
        activeTool({ [setOnMouseButton]: tool.toolName });
      }
    } else {
      setIsMprLinesActive(false);
      activeTool({ [setOnMouseButton]: tool.toolName });
    }
  };

  const handleTags = (name) => {
    setActiveButton(name);
    setisDescriptionActive(!isDescriptionActive);
  };

  const playCinema = (name) => {
    setActiveButton(name);
    setIsMprLinesActive(false);
    playClip();
  };

  const stopCinema = (name) => {
    setActiveButton(name);
    setIsMprLinesActive(false);
    stopClip();
  };

  const handleFlip = (name) => {
    setActiveButton(name);
    setIsMprLinesActive(false);
    flip(name);
  };
  // const handleFlipV = (name) => {
  //   setActiveButton(name);
  //   setIsMprLinesActive(false);
  //   flip({ flipHorizontal: false, flipVertical: true });
  // };

  const handleInvertColor = (name) => {
    setActiveButton(name);
    setIsMprLinesActive(false);
    setInvertColors(!invertColors);
  };

  const handleFrames = (e) => {
    const { value } = e.target;
    setFramesPerSecond(value);
    const viewport = renderingEngine.getViewport(`viewport-${activeViewport}`);
    if (value) {
      csToolsUtilities.cine.stopClip(viewport.element);
      csToolsUtilities.cine.playClip(viewport.element, {
        framesPerSecond: value,
      });
    }
  };

  const handleMpr = (mpr) => {
    if (mpr === "MPR 3D") {
      setLeftClick("Líneas MPR");
      setRightClick("Líneas MPR");
      setLeftActiveButton("");
      setRightActiveButton("");
      setIsActiveMPR(true);
      setActiveCrosshairs(true);
      setPreviousViewportInfo()
    } else {
      convertToMpr(mpr);
    }
  };

  const handleIntensity = (intensity) => {
    setIntensity(intensity);
  };

  const handleReset = (name) => {
    setActiveButton(name);
    setIsMprLinesActive(false);
    stopClip();
    resetViewport();
  };

  const handleSaveImage = (name) => {
    setActiveButton(name);
    stopClip();
    let respectiveViewport = isActiveMPR
      ? activeMprViewport
      : `viewport-${activeViewport}`;
    const viewport = renderingEngine.getViewport(respectiveViewport);
    toPng(viewport.element, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "VDViewport_image";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteAnnotations = () => {
    let respectiveViewport = isActiveMPR
      ? activeMprViewport
      : `viewport-${activeViewport}`;
    const annotationUIDs = annotation.selection.getAnnotationsSelected();
    annotationUIDs.forEach(annotation.state.removeAnnotation);
    const viewport = renderingEngine.getViewport(respectiveViewport);
    viewport.render();
  };
  const handleLinesTool = (name) => {
    setActiveButton(name);
    toolGroup.setToolEnabled(ReferenceLinesTool.toolName);
  };

  const toolButtons = [
    {
      name: "Descripciones",
      icon: <span style={iconStyle}>A</span>,
      tool: null,
      onClick: () => handleTags("Visibilidad Tags"),
      className: `${isActiveMPR && "disabled"}`,
    },
    {
      name: "Mover",
      icon: <ArrowsPointingOutIcon style={iconStyle} />,
      tool: PanTool,
    },
    {
      name: "Zoom",
      icon: <MagnifyingGlassPlusIcon style={iconStyle} />,
      tool: ZoomTool,
    },
    {
      name: "Magnificar",
      icon: <MagnifyingGlassCircleIcon style={iconStyle} />,
      tool: MagnifyTool,
      className: `${isActiveMPR && "disabled"}`,
    },
    {
      name: "Rotar",
      icon: <ArrowPathRoundedSquareIcon style={iconStyle} />,
      tool: PlanarRotateTool,
    },
    {
      name: "Brillo/Contraste",
      icon: <SunIcon style={iconStyle} />,
      tool: WindowLevelTool,
    },
    {
      name: "Medición",
      icon: <ArrowsRightLeftIcon style={iconStyle} />,
      tool: LengthTool,
    },
    {
      name: "Ángulos",
      icon: <ChevronRightIcon style={iconStyle} />,
      tool: AngleTool,
    },
    {
      name: "ROI PF",
      icon: <ViewfinderCircleIcon style={iconStyle} />,
      tool: ProbeTool,
    },
    {
      name: "ROI Rect",
      icon: <RectangleStackIcon style={iconStyle} />,
      tool: RectangleROITool,
    },
    {
      name: "ROI Elíptica",
      icon: <EllipsisHorizontalCircleIcon style={iconStyle} />,
      tool: EllipticalROITool,
    },
    {
      name: "Voltear H",
      icon: <ArrowUturnRightIcon style={iconStyle} />,
      tool: null,
      onClick: () => handleFlip("H"),
    },
    {
      name: "Voltear V",
      icon: <ArrowUturnDownIcon style={iconStyle} />,
      tool: null,
      onClick: () => handleFlip("V"),
    },
    {
      name: "Invertir Color",
      icon: <EyeDropperIcon style={iconStyle} />,
      tool: null,
      onClick: () => handleInvertColor("Invertir Color"),
    },
    {
      name: "Líneas Ref",
      icon: <MinusIcon style={iconStyle} />,
      tool: null,
      onClick: () => handleLinesTool("Líneas Ref"),
      className: ` ${(numViewports < 2 || isActiveMPR) && "disabled"} `,
    },
    {
      name: "Borrar",
      icon: <TrashIcon style={iconStyle} />,
      tool: null,
      onClick: () => handleDeleteAnnotations(),
      className: `${isMprLinesActive && "disabled"}`,
    },
    {
      name: "Reset",
      icon: <ArrowPathIcon style={iconStyle} />,
      tool: null,
      onClick: () => handleReset("Reset"),
      className: ` ${isActiveMPR && "disabled"} `,
    },
    {
      name: "Guardar Img",
      icon: <DocumentArrowDownIcon style={iconStyle} />,
      tool: null,
      onClick: () => handleSaveImage("Guardar Img"),
    },
  ];

  return (
    <nav className="bg-black btn-group flex-grow-0 border-0" role="group">
      <DisplayToolbarButton
        iconStyle={iconStyle}
        setNumViewports={setNumViewports}
      />

      <TagsModal
        iconStyle={iconStyle}
        activeViewport={activeViewport}
        isActiveMPR={isActiveMPR}
        renderingEngine={renderingEngine}
      />

      {toolButtons.map((button) => (
        <ToolButton
          key={button.name}
          name={button.name}
          icon={button.icon}
          onClick={(e) =>
            button.onClick
              ? button.onClick()
              : handleToolClick(e, button.tool, button.name)
          }
          isActive={activeButton === button.name}
          isLeftButton={leftActiveButton === button.name}
          isRightButton={rightActiveButton === button.name}
          isStackLengthMoreThan100={isStackLengthMoreThan100}
          className={button.className ? button.className : ""}
        />
      ))}
      {!isCinePlaying[activeViewport] && (
        <ToolButton
          key="Modo Cine"
          name="Modo Cine"
          icon=<FilmIcon style={iconStyle} />
          onClick={() => playCinema()}
          isActive={activeButton === "Modo Cine"}
          className={`${!isStackLengthMoreThan5 && "disabled"}`}
        />
      )}

      {isCinePlaying[activeViewport] && (
        <div className="d-flex bg-dark" style={{ fontSize: "11px" }}>
          <div className="py-2 d-flex gap-2 text-white">
            <label htmlFor="frames" className="form-label align-self-end">
              FPS:
            </label>
            <input
              type="number"
              className="form-control bg-dark text-white"
              min="1"
              max="100"
              id="frames"
              value={framesPerSecond}
              onChange={(e) => handleFrames(e)}
            />
          </div>

          <ToolButton
            key="Parar"
            name="Parar"
            icon={<StopCircleIcon style={iconStyle} />}
            onClick={() => stopCinema()}
            isActive={activeButton === "Parar"}
            isStackLengthMoreThan100={isStackLengthMoreThan100}
          />
        </div>
      )}
      <WindowToolbarButton
        iconStyle={iconStyle}
        activeViewport={activeViewport}
        renderingEngine={renderingEngine}
        setDescriptions={setDescriptions}
        activeMprViewport={activeMprViewport}
        isActiveMPR={isActiveMPR}
      />
      <MprToolbarButton
        redIconStyle={redIconStyle}
        handleMpr={handleMpr}
        isStackLengthMoreThan100={isStackLengthMoreThan100}
      />
      {isActiveMPR && (
        <ToolButton
          key="Líneas MPR"
          name="Líneas MPR"
          icon={<PlusIcon style={redIconStyle} />}
          onClick={(e) => handleToolClick(e, CrosshairsTool, "Líneas MPR")}
          isActive={activeButton === "Líneas MPR"}
          isLeftButton={leftActiveButton === "Líneas MPR"}
          isRightButton={rightActiveButton === "Líneas MPR"}
        />
      )}
      {isActiveMPR && (
        <MipToolbarButton
          redIconStyle={redIconStyle}
          handleIntensity={handleIntensity}
        />
      )}
      {isActiveMPR && (
        <ToolButton
          key="Cancelar MPR"
          name="Cancelar MPR"
          icon={<XCircleIcon style={redIconStyle} />}
          onClick={handleCancelMPR}
          isActive={activeButton === "Cancelar MPR"}
        />
      )}
      <ConfigModal
        iconStyle={iconStyle}
        setLeftClick={setLeftClick}
        setRightClick={setRightClick}
        setLeftActiveButton={setLeftActiveButton}
        setRightActiveButton={setRightActiveButton}
        activeTool={activeTool}
        setActiveButton={setActiveButton}
      />
    </nav>
  );
};
