import { Thumbnail } from "./Thumbnail";
import { useState,useEffect } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";

export const SidebarThumbnail = ({
  serie,
  stack,
  study,
  engine,
  onClick,
  isClicked,
  setMgViewports,
}) => {
  const [thumbName, setThumbName] = useState('');
  return (
    <div>
      <Thumbnail
        serie={serie}
        stack={stack}
        study = {study}
        engine={engine}
        setThumbName = {setThumbName}
        onClick={onClick}
        isClicked={isClicked}
        setMgViewports={setMgViewports}
      />
      <div className="d-flex justify-content-between text-white">
        {/* <p>
          <span style={{ color: isClicked ? "#4033F2" : "#909294" }}><strong>S:</strong></span>
          {serie}
        </p> */}
        <p>{(thumbName.length > 15) ? 'Info de estudio':thumbName }</p>
        <div className="d-flex gap-1 align-items-center">
          <PhotoIcon
            style={{
              width: "16px",
              height: "16px",
              color: isClicked ? "#4033F2" : "#909294",
              marginBottom: "15px",
            }}
          />
          <p>{stack?.length}</p>
        </div>
      </div>
    </div>
  );
};
