export const enviroment = {
    api:'https://coahuila.disenodesalud.com.mx/api/get-images?soap=',
    // api:'https://ed3a-2806-103e-15-1e6-11d4-505d-85d6-877f.ngrok-free.app/api/get-images?soap=',
    pacs : 'https://imgcoah.disenodesalud.com.mx/InnovaPacs/',
    production :  false
 
}































































